import React, { useContext, useEffect, useState } from 'react';
import { FontSizeContext } from 'contexts/FontSizeContext';
import { ThemeContext } from 'contexts/ThemeContext';
import { ThemeProvider } from 'styled-components';
import { SettingsColorSchemes, SettingsFontSizes } from 'constants/enums';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { fontSize1, fontSize2, fontSize3, FontType } from 'styles/theme/fonts';
import {
  dark,
  defaultTheme,
  ThemeConfigType,
  ThemeType,
  yellow,
  defaultThemeConfig,
  darkThemeConfig,
  yellowThemeConfig,
} from 'styles/theme/theme';
import DefaultLayout from './components/DefaultLayout';
import { ConfigProvider, Empty } from 'antd';
import { GlobalStyles } from './styles';
import AuthPage from './pages/AuthPage';
import { useIntl } from 'react-intl';
import EmbedPage from './pages/EmbedPage';
import device from './utils/device';
import { useSystemSettingDispatch } from './contexts/SystemSettingContext';
import { useOpenedTypeState } from './contexts/OpenedTypeContext';
import { ClassNameUtil } from './utils/className';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

type ThemeMapType<T> = {
  [key in SettingsColorSchemes]: T;
};

const themes: ThemeMapType<ThemeType> = {
  [SettingsColorSchemes.default]: defaultTheme,
  [SettingsColorSchemes.whiteOnBlack]: dark,
  [SettingsColorSchemes.blackOnYellow]: yellow,
};
const themeConfigs: ThemeMapType<ThemeConfigType> = {
  [SettingsColorSchemes.default]: defaultThemeConfig,
  [SettingsColorSchemes.whiteOnBlack]: darkThemeConfig,
  [SettingsColorSchemes.blackOnYellow]: yellowThemeConfig,
};

type Fonts = {
  [key in SettingsFontSizes]: FontType;
};

const fonts: Fonts = {
  [SettingsFontSizes.fontSize1]: fontSize1,
  [SettingsFontSizes.fontSize2]: fontSize2,
  [SettingsFontSizes.fontSize3]: fontSize3,
};

function App() {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [isDesktop, setIsDesktop] = useState<boolean>(true);

  const { theme } = useContext(ThemeContext);
  const { fontSize } = useContext(FontSizeContext);
  const intl = useIntl();
  const updateSettings = useSystemSettingDispatch();
  const { openTutorial, openGeoproduct, selectedGeoproduct, rightSidebars, leftSidebars } = useOpenedTypeState();

  const isModalOpen = !![openTutorial, openGeoproduct, selectedGeoproduct].filter(Boolean).length;
  const isSidebarOpen = !!Object.keys({ ...rightSidebars, ...leftSidebars }).length;
  const themeConfig = (isDesktop ? themeConfigs[theme].desktop : themeConfigs[theme].mobile) || {};

  dayjs.extend(customParseFormat);

  useEffect(() => {
    updateSettings({ type: 'UPDATE_DEVICE', payload: { isMobile, isTablet, isDesktop } });
  }, [isMobile, isTablet]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(device.mobile());
      setIsTablet(device.tablet());
      setIsDesktop(device.desktop());
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ThemeProvider theme={{ ...themes[theme], ...fonts[fontSize] }}>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Ubuntu',
          },
          ...themeConfig,
        }}
        renderEmpty={() => (
          <Empty
            description={
              <div className="ant-empty-description">{intl.formatMessage({ id: 'general.found_no_data' })}</div>
            }
            image={null}
            imageStyle={{ height: 0 }}
          />
        )}
      >
        <div className={ClassNameUtil.create(['App']).add({ isModalOpen }).getClassName()}>
          <GlobalStyles isSidebarOpen={isSidebarOpen} />
          <BrowserRouter>
            <Routes>
              <Route
                path="/auth-token/:token/:tokenExpire/:refreshToken/:refreshExpire/:refreshTokenExpiresSetAt/:roleId/:isProxyUser"
                element={<AuthPage />}
              />
              <Route path="/map" element={<EmbedPage />} />
              <Route path="*" element={<DefaultLayout />} />
            </Routes>
          </BrowserRouter>
        </div>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
