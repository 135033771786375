import React, { Dispatch, SetStateAction, useState } from 'react';
import { Tag, Typography } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Autoplay } from 'swiper/modules';
import { Card } from 'ui/card';
import { Button, Image } from 'ui';
import { useIntl } from 'react-intl';
import { StyledProjectCard } from './style';
import { useUserState } from 'contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import UnauthenticatedModal from 'components/Modals/UnauthenticatedModal';
import useQueryApiClient from 'utils/useQueryApiClient';
import { UserGroups } from 'constants/userGroups';
import { useProjectDispatch } from 'contexts/ProjectContext';
import VoteButton, { VotingProject } from 'components/VoteButton';
import { ImageSizeType } from 'ui/image';
import { LayoutRenderHandler } from 'interfaces/shared';

const { Title } = Typography;

export type ListType = 'compact' | 'list';

export interface ProjectItemProps {
  project: any;
  className?: string;
  listView?: boolean;
  children?: React.ReactNode;
  isVisibleSeDescBtn?: boolean;
  setActiveProject?: Dispatch<SetStateAction<number | null>> | Function;
  onDescriptionClick?: () => void;
  projectViewSide?: 'left' | 'right';
  id?: number;
  elipseTitle?: boolean;
  currentPage?: number;
  isSelected?: boolean;
  imageSize?: ImageSizeType;
  type?: 'idea' | 'project';
  renderLayout?: Partial<Record<ListType, LayoutRenderHandler<'image' | 'title' | 'voteBtn' | 'seeMore'>>>;
}

export const ProjectCard: React.FC<ProjectItemProps> = ({
  project,
  className,
  listView = 'list',
  children,
  isVisibleSeDescBtn,
  setActiveProject,
  onDescriptionClick,
  projectViewSide = 'right',
  elipseTitle,
  id,
  currentPage,
  isSelected,
  imageSize,
  type = 'project',
  renderLayout,
}) => {
  const intl = useIntl();
  const user = useUserState();
  const navigate = useNavigate();
  const { pictures, name, state } = project;
  const [isOpenUnauthenticated, setIsOpenUnauthenticated] = useState(false);
  const dispatch = useProjectDispatch();
  const { appendData: submitVote, isLoading: isLoadingVotion } = useQueryApiClient({
    request: {
      url: 'api/v1/tapis/vote-for-project',
      method: 'POST',
    },
    onSuccess(_, project) {
      onVoted(project);
    },
  });

  const activeRole = user.roles.find((e) => e.id === user.selectedRole)?.code;

  const handleOpenProjectView = () => {
    navigate(`/main?project-view=open&side=${projectViewSide}&geoProjectId=${id}`, { state: { currentPage } });
  };

  const onVoted = (project: VotingProject | null) => {
    if (project) {
      dispatch({ type: 'VOTE_PROJECT', payload: project?.id });
    }
  };

  const appendVote = (project: VotingProject | null) => {
    if (!project) {
      return;
    }

    if (activeRole === UserGroups.proxy) {
      return onVoted(project);
    }

    const vote = { project_id: project?.id, is_active: !project?.has_voted };

    submitVote({ vote }, undefined, project);
  };

  const renderImage = () =>
    listView ? (
      <Image src={pictures[0]} size={imageSize} type={type === 'idea' ? 'idea' : undefined} />
    ) : (
      <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        loop={false}
        modules={[Navigation, Pagination, Mousewheel, Autoplay]}
        className="project-item__swiper"
      >
        {pictures?.length ? (
          pictures?.map((image: any, idx: number) => (
            <SwiperSlide key={idx}>
              <Image src={image} size={imageSize} type={type === 'idea' ? 'idea' : undefined} />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <Image size={imageSize} type={type === 'idea' ? 'idea' : undefined} />
          </SwiperSlide>
        )}
      </Swiper>
    );

  const renderVoteBtn = () =>
    children ? (
      children
    ) : (
      <>
        {state === 'in_voting' ? (
          <VoteButton
            project={project}
            button={{ loading: isLoadingVotion }}
            tooltip={{ placement: 'top' }}
            handleVote={appendVote}
          />
        ) : (
          <Tag className={`status ${project.state} ${type}`}>
            {intl.formatMessage({ id: `participation_budget.${project.state}` })}
          </Tag>
        )}
      </>
    );

  const renderSeeMoreBtn = () =>
    isVisibleSeDescBtn && (
      <Button
        onClick={onDescriptionClick ? onDescriptionClick : handleOpenProjectView}
        label={intl.formatMessage({ id: 'participation_budget.projects_see_description' })}
        className="project-item__desc-btn"
        border={false}
      />
    );

  const renderTitle = () => (
    <Title className="project_title " level={4}>
      {name}
    </Title>
  );

  const renderContent = () => {
    const layoutHandler = renderLayout && renderLayout[listView ? 'compact' : 'list'];

    if (layoutHandler) {
      return layoutHandler({
        image: renderImage,
        title: renderTitle,
        voteBtn: renderVoteBtn,
        seeMore: renderSeeMoreBtn,
      });
    }

    return (
      <>
        {renderImage()}
        <div className="project-item__content-wrapper ">
          {renderTitle()}
          <div className="project-item__swiper-btns">
            {renderVoteBtn()}
            {renderSeeMoreBtn()}
          </div>
        </div>
      </>
    );
  };

  return (
    <StyledProjectCard
      className={`${listView ? 'list-view' : ''} ${className} ${elipseTitle && 'ellipse-title'} ${
        isSelected !== undefined && isSelected ? 'selected' : ''
      }`}
      onClick={() => {
        if (setActiveProject) {
          setActiveProject(project.id);
        }
      }}
    >
      <Card className={`full-height ${className}`}>{renderContent()}</Card>
      <UnauthenticatedModal setShowModal={setIsOpenUnauthenticated} showModal={isOpenUnauthenticated} />
    </StyledProjectCard>
  );
};
