import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { SidebarDrawer } from 'ui';
import { StyledContent, StyledPage } from 'styles/layout/SidebarMap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { MapComponent } from 'components/Map/MapComponent';
import { LayerSettings } from 'components/Map/LayerSettings';
import { CoordsComponent } from 'components/Map/CoordsComponent';
import { LayerSwitcher } from 'components/Map/LayerSwitcher';
import { Measure } from 'components/Map/Measure';
import Sidebars from './Components/Sidebars';
import { useOpenedTypeDispatch, useOpenedTypeState } from 'contexts/OpenedTypeContext';
import { MapClickResults } from 'components/Map/MapClickResults';
import RightSidebars from './Components/RightSidebars/EmbedsSideBar';
import EmbedCreateButton from 'components/Map/EmbedCreateButton';
import { useApplyForNotifications } from 'contexts/ApplyForNotificationsContext';
import MapContext from 'contexts/MapContext';
import useQueryApiClient from 'utils/useQueryApiClient';
import { applyEmbedMapState } from 'utils/embedMapUtils';
import UnauthenticatedModal from 'components/Modals/UnauthenticatedModal';
import { useNotificationHeader } from 'contexts/NotificationHeaderContext';
import { usePlannedDocumentsFilterContext } from 'contexts/PlannedDocumentsFilterContext';
import { useMapClickResultsOpening } from 'contexts/MapClickResultsOpeningContext';
import { ProposalSubmitContext } from 'contexts/ProposalSubmitContext';
import MyParticipationTabs from './Components/Sidebars/MyParticipationTabs/MyParticipationTabs';
import useJwt from 'utils/useJwt';
import { ProjectView } from './Components/Sidebars/ProjectView';
import { IdeaView } from './Components/Sidebars/IdeaView';
import { IdeaSubmisson } from './Components/Sidebars/MyParticipationTabs/components/Ideas/IdeaSubmission';
import LBISProjectsLayer from 'components/Map/MapLayers/LBISProjectsLayer';
import { ProjectType } from './Components/Sidebars/Project';
import MunicipalityModal from './Components/Modals/MunicipalityModal';
import ProjectModal, { getNavMap } from './Components/Modals/ProjectModal';
import { useProjectDispatch } from 'contexts/ProjectContext';
import { useParticipationBudgetDispatch } from 'contexts/ParticipationBudgetContext';
import { pages } from 'constants/pages';
import { SAVE_ORGANIZATIONS, useOrganizationsDispatch } from 'contexts/OrganizationsContext';
import { useUserState } from 'contexts/UserContext';
import { UserGroups } from 'constants/userGroups';
import { ProxyUserActions } from './Components/Modals/ProxyUserActions';
import { getProjectUrl } from 'utils/globalFunctions';
import { ClassNameUtil } from 'utils/className';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import PortalSectionButtons from 'components/PortalSectionButtons';
import useCheckFooterStatus from 'utils/useCheckFooterStatus';
import MapActions from 'components/MapActions';
import { MapControls } from 'components/Map/MapControls';
import { Splitter, SplitterProps } from 'ui/splitter';
import { Attribution } from 'components/Map/Attribution';
import { Scale } from 'components/Map/Scale';
import CloseButton from '../../components/CloseButton';

export interface MeasureProps {
  setEnabledMeasureButton: Function;
  enabledMeasureButton: 'line' | 'poly' | null;
}

export interface CoordinatesWindowPosition {
  x: number;
  y: number;
  mapWidth: number;
  mapHeight: number;
}

const LayoutPage = () => {
  const {
    device: { isDesktop },
  } = useSystemSettingState();
  const { state, pathname, hash, search } = useLocation();

  const [isOpenLayerSettings, setIsOpenLayerSettings] = useState<boolean>(false);
  const [isOpenEmbedded, setIsOpenedEmbedded] = useState<boolean>(false);
  const [enabledMeasureButton, setEnabledMeasureButton] = useState<'line' | 'poly' | null>(null);
  const [visibleLayers, setVisibleLayers] = useState<string[] | undefined>(undefined);
  const [openedUserEmbedUuid, setOpenedUserEmbedUuid] = useState<string | null>(null);
  const [isOpenUnauthenticated, setIsOpenUnauthenticated] = useState(false);
  const [showCoordinatesWindow, setShowCoordinatesWindow] = useState(false);
  const [isOpenProjectModal, setIsOpenProjectModal] = useState<boolean>(false);
  const [isOpenMunicipalityModal, setIsOpenMunicipalityModal] = useState<boolean>(false);
  const [submittedProjectTitle, setSubmittedProjectTitle] = useState('');
  const [municipality, setMunicipality] = useState<string | null>(null);
  const [project, setProject] = useState<ProjectType | null>(null);
  const [coordinatesWindowPosition, setCoordinatesWindowPosition] = useState<CoordinatesWindowPosition | null>(null);
  const [isOpenUserNotification, setIsOpenUserNotification] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(
    (isDesktop && !search.includes('open')) || search.includes('participation-budget')
      ? 0
      : (window.screen.height - 60) / 2
  );

  const { isTokenActive } = useJwt();
  let [searchParams] = useSearchParams();

  const map = useContext(MapContext);
  const { value: applyForNotificationsValue } = useApplyForNotifications();
  const intl = useIntl();
  const dispatch = useOpenedTypeDispatch();
  const navigate = useNavigate();
  const states = useOpenedTypeState();
  const { toggleHandler: toggleProposalSubmitHandler, isOpen: isProposalSumbitOpen } =
    useContext(ProposalSubmitContext);
  const { height: notificationHeight } = useNotificationHeader();
  const { setFilterParams } = usePlannedDocumentsFilterContext();
  const { open: isOpenMapClickResults, setOpen: setIsOpenMapClickResults } = useMapClickResultsOpening();
  const user = useUserState();
  const dispatchSettings = useProjectDispatch();
  const updateOrganizations = useOrganizationsDispatch();
  const dispatchBudgetSettings = useParticipationBudgetDispatch();

  const { renderFooter } = useCheckFooterStatus();

  const {
    openedTapis,
    openTapisDocument,
    openGeoproduct,
    openMyParticipation,
    openParticipationBudget,
    openProject,
    openMunicipalProject,
    openProjectView,
    openIdeaView,
    openVoteView,
    openSubmitProject,
    openSubmitProjectCreateForm,
    openSubmitIdea,
    openedMapType,
    openPlannedDocuments,
    openMyParticipationProposals,
    openTutorial,
    rightSidebars,
    leftSidebars,
    openSubmitProposal: isOpenSubmitProposal,
  } = states;
  const isProxyUser = user.roles.find((e) => e.id === user.selectedRole)?.code === UserGroups.proxy;
  const openSubmitProjectRef = useRef(openSubmitProject);
  const isSidebarOpen = !!Object.keys({ ...rightSidebars, ...leftSidebars }).length;

  useEffect(() => {
    openSubmitProjectRef.current = openSubmitProject;
  }, [openSubmitProject]);

  useEffect(() => {
    if (isDesktop) {
      setHeight(0);
    }

    if (!search.includes('open') || search.includes('participation-budget') || search.includes('planned-documents')) {
      setHeight(0);
    }
  }, [search]);

  useEffect(() => {
    if (!!applyForNotificationsValue && applyForNotificationsValue.open && !isOpenMapClickResults) {
      setIsOpenMapClickResults(true);
    }
  }, [applyForNotificationsValue]);

  useEffect(() => {
    if (searchParams.get('planned-documents') && !openedTapis) {
      dispatch({ type: 'OPENED_TAPIS' });
    } else if (!searchParams.get('planned-documents') && openedTapis) {
      dispatch({ type: 'CLOSED_TAPIS' });
    }
    if (searchParams.get('planned-documents') === 'open' || (!searchParams.toString().length && !hash)) {
      dispatch({ type: 'OPEN_TAPIS' });
    } else if (
      searchParams.get('planned-documents') !== 'open' &&
      searchParams.toString().length &&
      searchParams.get('document') !== 'open' &&
      openTapisDocument
    ) {
      dispatch({ type: 'CLOSE_TAPIS' });
    }
    if (searchParams.get('participation-budget') === 'open' && !openParticipationBudget) {
      const data = { search: { state: ['in_voting'], order: '{"count_of_votes":"desc"}' } };

      dispatch({ type: 'OPEN_PARTICIPATION_BUDGET' });
      appendData(data, undefined, data); // refetch projects
    } else if (searchParams.get('participation-budget') !== 'open' && openParticipationBudget) {
      dispatch({ type: 'CLOSE_PARTICIPATION_BUDGET' });
    }
    if (searchParams.get('geoproduct') === 'open' && !openGeoproduct) {
      dispatch({ type: 'OPEN_GEOPRODUCT' });
    } else if (searchParams.get('geoproduct') !== 'open' && openGeoproduct) {
      dispatch({ type: 'CLOSE_GEOPRODUCT' });
    }
    if (searchParams.get('my-participation') === 'open' && isTokenActive() && !openMyParticipation) {
      dispatch({ type: 'OPEN_MY_PARTICIPATION' });
    } else if (searchParams.get('my-participation') !== 'open' && openMyParticipation) {
      dispatch({ type: 'CLOSE_MY_PARTICIPATION' });
    } else if (searchParams.get('my-participation') === 'open' && !isTokenActive()) {
      navigate('/main');
    }
    if (
      searchParams.get('my-participation') === 'open' &&
      (searchParams.get('tab') === 'my_proposals_tab' || searchParams.get('tab') === 'notices_tab') &&
      isTokenActive() &&
      !openMyParticipationProposals
    ) {
      dispatch({ type: 'OPEN_MY_PARTICIPATION_PROPOSALS' });
    } else if (
      searchParams.get('tab') !== 'my_proposals_tab' &&
      searchParams.get('tab') !== 'notices_tab' &&
      openMyParticipationProposals
    ) {
      dispatch({ type: 'CLOSE_MY_PARTICIPATION_PROPOSALS' });
      // if still in my participation, open my participation again for changing the map to lbis
      if (searchParams.get('my-participation') === 'open' && isTokenActive()) {
        dispatch({ type: 'OPEN_MY_PARTICIPATION' });
      }
    }
    if (searchParams.get('project') === 'open' && !openProject) {
      dispatch({ type: 'OPEN_PROJECT' });
      setProject((prevProject: any) => ({
        ...prevProject,
        coordinatesForModal: undefined,
      }));
    } else if (searchParams.get('project') !== 'open' && openProject) {
      dispatch({ type: 'CLOSE_PROJECT' });
    }
    if (searchParams.get('municipal-project') === 'open' && !openMunicipalProject) {
      dispatch({ type: 'OPEN_MUNICIPAL_PROJECT' });
      setMunicipality(searchParams.get('atvk_id'));
    } else if (searchParams.get('municipal-project') !== 'open' && openMunicipalProject) {
      dispatch({ type: 'CLOSE_MUNICIPAL_PROJECT' });
    }
    if (searchParams.get('project-view') === 'open' && !openProjectView) {
      dispatch({ type: 'OPEN_PROJECT_VIEW' });
    } else if (searchParams.get('project-view') !== 'open' && openProjectView) {
      dispatch({ type: 'CLOSE_PROJECT_VIEW' });
    }
    if (searchParams.get('vote-view') === 'open' && !openVoteView) {
      dispatch({ type: 'OPEN_VOTE_VIEW' });
    } else if (searchParams.get('vote-view') !== 'open' && openVoteView) {
      dispatch({ type: 'CLOSE_VOTE_VIEW' });
    }
    if (searchParams.get('idea-view') === 'open' && !openIdeaView) {
      dispatch({ type: 'OPEN_IDEA_VIEW' });
    } else if (searchParams.get('idea-view') !== 'open' && openIdeaView) {
      dispatch({ type: 'CLOSE_IDEA_VIEW' });
    }

    if (searchParams.get('submit-project') === 'open' && isTokenActive() && !openSubmitProject) {
      dispatch({ type: 'OPEN_SUBMIT_PROJECT' });
    } else if (searchParams.get('submit-project') !== 'open' && openSubmitProject) {
      dispatch({ type: 'CLOSE_SUBMIT_PROJECT' });
    } else if (searchParams.get('submit-project') === 'open' && !isTokenActive()) {
      navigate('/main');
    }

    if (searchParams.get('submit-project-form') === 'open' && isTokenActive() && !openSubmitProjectCreateForm) {
      dispatch({ type: 'OPEN_SUBMIT_PROJECT_CREATE_FORM' });
    } else if (searchParams.get('submit-project-form') !== 'open' && openSubmitProjectCreateForm) {
      dispatch({ type: 'CLOSE_SUBMIT_PROJECT_CREATE_FORM' });
    } else if (searchParams.get('submit-project-form') === 'open' && !isTokenActive()) {
      navigate('/main');
    }

    if (searchParams.get('submit-idea') === 'open' && isTokenActive() && !openSubmitIdea) {
      dispatch({ type: 'OPEN_SUBMIT_IDEA' });
    } else if (searchParams.get('submit-idea') !== 'open' && openSubmitIdea) {
      dispatch({ type: 'CLOSE_SUBMIT_IDEA' });
    } else if (searchParams.get('submit-idea') === 'open' && !isTokenActive()) {
      navigate('/main');
    }

    if (searchParams.get('project-modal') === 'open' && !isOpenProjectModal) {
      if (isDesktop) {
        setIsOpenProjectModal(true);
      } else {
        const navigationMap = getNavMap(project?.id).project;

        navigate(navigationMap.url, navigationMap.options);
      }
    } else if (searchParams.get('project-modal') !== 'open' && isOpenProjectModal) {
      setIsOpenProjectModal(false);
    }

    if (searchParams.get('document') === 'open' && !openPlannedDocuments) {
      dispatch({ type: 'OPEN_PANNING_DOCUMENTS' });
    } else if (searchParams.get('document') !== 'open' && openPlannedDocuments) {
      dispatch({ type: 'CLOSE_PANNING_DOCUMENTS' });
    }

    // handle link from embedded iframe
    if (searchParams.get('embed_uuid')) {
      setOpenedUserEmbedUuid(searchParams.get('embed_uuid'));
    }
    if (isProposalSumbitOpen) toggleProposalSubmitHandler();
  }, [searchParams]);

  useEffect(() => {
    if (pathname === pages.tutorials.url && !openTutorial) {
      dispatch({ type: 'OPEN_TUTORIAL' });
    }
  }, [pathname]);

  useEffect(() => {
    if (searchParams.get('submit-idea') === 'open') {
      return;
    } else if (searchParams.get('municipal-modal') === 'open' && !isOpenMunicipalityModal) {
      if (isDesktop) {
        setIsOpenMunicipalityModal(true);
      } else {
        navigate(`/main?municipal-project=open&atvk_id=${municipality}`);
      }
    } else if (searchParams.get('project') === 'open' && searchParams.get('municipal-modal') === 'open') {
      if (isDesktop) {
        setIsOpenMunicipalityModal(true);
      } else {
        navigate(`/main?municipal-project=open&atvk_id=${municipality}`);
      }
    } else if (searchParams.get('municipal-modal') !== 'open' && isOpenMunicipalityModal) {
      setIsOpenMunicipalityModal(false);
    }
  }, [searchParams, coordinatesWindowPosition]);

  useEffect(() => {
    const data = { search: { state: ['in_voting'], order: '{"count_of_votes":"desc"}' } };

    appendData(data, undefined, data);
  }, []);

  useEffect(() => {
    if (searchParams.get('project-modal') === 'open' && !project) {
      refetchOneProject();
    }
  }, [searchParams]);

  const { data: embeddedDataByUuid } = useQueryApiClient({
    request: {
      url: `api/v1/user-embeds/${openedUserEmbedUuid}/uuid`,
      disableOnMount: !openedUserEmbedUuid,
    },
  });

  useEffect(() => {
    if (map && embeddedDataByUuid?.data) {
      const embedData = JSON.parse(embeddedDataByUuid.data);
      const visibleLayers = applyEmbedMapState(map, embedData, dispatch);
      setVisibleLayers(visibleLayers);
    }
  }, [map, embeddedDataByUuid]);

  const { appendData } = useQueryApiClient({
    request: {
      url: `/api/v1/tapis/projects`,
      method: 'GET',
      disableOnMount: true,
    },
    onSuccess: (response, { search = {} }) => {
      dispatchSettings({
        type: 'SAVE_PAYLOAD',
        payload: {
          projects: response,
          appendData,
          search,
        },
      });
    },
    onError: () => {
      dispatchSettings({
        type: 'SAVE_PAYLOAD',
        payload: {
          projects: [],
          appendData,
          search: {},
        },
      });
    },
  });

  useQueryApiClient({
    request: {
      url: `api/v1/tapis/organisations/`,
      method: 'GET',
    },
    onSuccess: (organizations) => {
      updateOrganizations({
        type: SAVE_ORGANIZATIONS,
        payload: { organizations },
      });
    },
    onError: () => {
      updateOrganizations({
        type: SAVE_ORGANIZATIONS,
        payload: { organizations: [] },
      });
    },
  });

  const { appendData: budgetAppendData } = useQueryApiClient({
    request: {
      url: '/api/v1/tapis/participation_budgets',
      method: 'GET',
      data: { search: { cantLeaveEmpty: 'cantLeaveEmpty' } },
    },
    onSuccess: (response) => {
      dispatchBudgetSettings({
        type: 'SAVE_PAYLOAD',
        payload: {
          budgets: response ? response.toSorted(({ year: a }: any, { year: b }: any) => b - a) : [],
          appendData: budgetAppendData,
        },
      });
    },
    onError: () => {
      dispatchBudgetSettings({
        type: 'SAVE_PAYLOAD',
        payload: {
          budgets: [],
          appendData: budgetAppendData,
        },
      });
    },
  });

  const { isLoading: isGetOneProjectLoading, refetch: refetchOneProject } = useQueryApiClient({
    request: {
      url: getProjectUrl(searchParams.get('geoProjectId') || ''),
      disableOnMount: true,
    },
    onSuccess(res) {
      setProject({ ...res, ...res.versions[0] });
    },
  });

  const onMarkerClick = (project: ProjectType, coordinatesForModal: CoordinatesWindowPosition) => {
    if (openSubmitProjectRef.current) {
      return;
    }

    let url = new URL(window.location.href);

    url.searchParams.set('geoProjectId', project?.id.toString());
    url.searchParams.set('project-modal', 'open');
    url.searchParams.delete('atvk_id');
    url.searchParams.delete('municipal-modal');

    navigate(`/main${url.search}`);

    setProject({
      ...project,
      coordinatesForModal,
    });
  };

  const onCloseProjectModal = () => {
    setIsOpenProjectModal(false);
  };

  const onCloseMunicipalityModal = () => {
    setIsOpenMunicipalityModal(false);
    const url = new URL(window.location.href);

    if (!url.searchParams.get('municipal-project')) {
      setMunicipality(null);
    }
  };

  const onMunicipalityClick = (municipality: string, coordinatesForModal: CoordinatesWindowPosition) => {
    setMunicipality(municipality);
    setCoordinatesWindowPosition(coordinatesForModal);

    let url = new URL(window.location.href);

    url.searchParams.set('atvk_id', municipality);
    url.searchParams.set('municipal-modal', 'open');

    url.searchParams.delete('geoProjectId');
    url.searchParams.delete('project-modal');

    navigate(`/main${url.search}`);
  };

  const handleBackClick = () => {
    if (searchParams.get('idea-view') == 'open') {
      navigate('/main?my-participation=open&tab=ideas_tab&temp=temp', { state: { currentPage: state?.currentPage } });
    } else if (searchParams.get('project-view') == 'open') {
      navigate('/main?my-participation=open&tab=submitted_projects_tab', {
        state: { currentPage: state?.currentPage },
      });
    } else if (searchParams.get('vote-view') == 'open') {
      // TODO: for now there is no my votes, after adding check vote section that when view vote info and return to vote list check current list page
      navigate('/main?my-participation=open&tab=my_votes_tab');
    } else {
      navigate(-1);
    }
  };

  const handleCloseMyParticipation = () => {
    if (!!searchParams.get('tab')) {
      if (searchParams.get('tab') === 'my_proposals_tab' || searchParams.get('tab') === 'notices_tab') {
        navigate('?planned-documents=open');
      } else {
        navigate('?participation-budget=open');
      }
      dispatch({ type: 'CLOSE_MY_PARTICIPATION' });
    } else {
      navigate(-1);
    }
  };

  const renderMap = () => (
    <>
      <StyledContent
        className={ClassNameUtil.create(['map-sidebar'])
          .add({
            openGeoproduct: !!openGeoproduct,
            isOpenMapClickResults: isOpenMapClickResults,
            openPlannedDocuments: !!openPlannedDocuments,
            openTapisDocument: !!openTapisDocument,
          })
          .getClassName()}
        style={height ? { height: `${height}px`, minHeight: `${height}px` } : {}}
      >
        {(!openedUserEmbedUuid || visibleLayers) && (
          <>
            <MapComponent zoomButtons={openedMapType === 'lbis'}>
              <LBISProjectsLayer
                onMarkerClick={onMarkerClick}
                onCloseProjectModal={onCloseProjectModal}
                searchParams={searchParams}
                onMunicipalityClick={onMunicipalityClick}
                onCloseMunicipalityModal={onCloseMunicipalityModal}
                municipality={municipality}
              />
              <MapActions />
              <ProjectModal
                project={project}
                isOpenProjectModal={isOpenProjectModal}
                setIsOpenProjectModal={setIsOpenProjectModal}
                isProjectLoading={isGetOneProjectLoading}
                type={project && project.year === undefined ? 'idea' : 'project'}
              />

              <MunicipalityModal
                municipality={municipality}
                isOpenMunicipalityModal={isOpenMunicipalityModal}
                onCloseMunicipalityModal={onCloseMunicipalityModal}
                coordinatesWindowPosition={coordinatesWindowPosition}
              />
              {(openedMapType === 'tapis' || openedMapType === 'geo') && (
                <CoordsComponent
                  setShowCoordinatesWindow={setShowCoordinatesWindow}
                  showCoordinatesWindow={showCoordinatesWindow}
                  enabledMeasureButton={enabledMeasureButton}
                  setEnabledMeasureButton={setEnabledMeasureButton}
                />
              )}
              {isDesktop && (
                <LayerSwitcher setIsOpenLayerSettings={setIsOpenLayerSettings} visibleLayers={visibleLayers} />
              )}
              {isDesktop && renderMapInteractions()}
            </MapComponent>
            {isProxyUser && <ProxyUserActions />}
          </>
        )}
      </StyledContent>
      {!isDesktop && renderMapInteractions()}
    </>
  );

  const renderMapInteractions = () => (
    <>
      <MapControls
        setEnabledMeasureButton={setEnabledMeasureButton}
        enabledMeasureButton={enabledMeasureButton}
        setShowCoordinatesWindow={setShowCoordinatesWindow}
        showCoordinatesWindow={showCoordinatesWindow}
        setIsOpenedEmbedded={setIsOpenedEmbedded}
        setShowModal={setIsOpenUnauthenticated}
        hide={openedMapType !== 'tapis' && openedMapType !== 'geo' ? ['measure', 'embed'] : undefined}
      />
      {!isDesktop && <LayerSwitcher setIsOpenLayerSettings={setIsOpenLayerSettings} visibleLayers={visibleLayers} />}
      <Scale />
      <Attribution />
    </>
  );

  const renderSideBars = () => (
    <>
      <Sidebars
        isOpenPlannedDocuments={openPlannedDocuments as boolean}
        isOpenGeoproducts={openGeoproduct as boolean}
        isOpenParticipationBudget={openParticipationBudget as boolean}
        isOpenProject={openProject as boolean}
        isOpenMunicipalProject={openMunicipalProject as boolean}
        setMunicipality={setMunicipality}
        onUserNotificationsOpenUpdate={setIsOpenUserNotification}
        side={searchParams.get('side') === 'right' ? 'right' : 'left'}
      />
      <SidebarDrawer
        title={intl.formatMessage({ id: 'layer_settings.map_layers' })}
        isOpen={isOpenLayerSettings}
        onClose={() => setIsOpenLayerSettings(false)}
        side="right"
      >
        <LayerSettings visibleLayers={visibleLayers} />
      </SidebarDrawer>
      {!isProxyUser && !isOpenEmbedded && (openedMapType === 'tapis' || openedMapType === 'geo') ? (
        <SidebarDrawer
          title={openedMapType === 'tapis' ? 'Teritorijas izmantošana' : 'Rezultāti'}
          isOpen={isOpenMapClickResults}
          onClose={() => {
            setIsOpenMapClickResults(false);
            setFilterParams({});
          }}
          className="map-click-results sidebar-style-1"
          side="right"
          scrollShadow={!isDesktop}
        >
          <MapClickResults
            isShowCoordinatesWindowOpen={showCoordinatesWindow}
            setIsOpenMapClickResults={setIsOpenMapClickResults}
            isOpenMapClickResults={isOpenMapClickResults}
            isOpenPlannedDocuments={openPlannedDocuments as boolean}
          />
        </SidebarDrawer>
      ) : null}

      {openMyParticipation ? (
        <SidebarDrawer
          title={intl.formatMessage({ id: 'my_participation.my_participation' })}
          breadcrumb={[{ path: '/', name: intl.formatMessage({ id: 'general.start' }) }]}
          isOpen={openMyParticipation}
          showBreadcrumb={isDesktop}
          closeIcon={isDesktop ? undefined : <CloseButton onClick={handleCloseMyParticipation} />}
          onClose={handleCloseMyParticipation}
          width="50%"
          className="sidebar-style-2 my-participation"
          side="right"
          scrollShadow={!isDesktop}
          dividerVisible={isDesktop}
        >
          <MyParticipationTabs />
        </SidebarDrawer>
      ) : null}

      {openSubmitIdea ? (
        <SidebarDrawer
          title={intl.formatMessage({ id: 'my_participation.submission_idea' })}
          breadcrumb={[
            { path: '/', name: intl.formatMessage({ id: 'general.start' }) },
            {
              path: '/main?my-participation=open&tab=ideas_tab',
              name: intl.formatMessage({ id: 'my_participation.tab_title.ideas' }),
            },
          ]}
          isOpen={openSubmitIdea}
          onClose={() => {
            navigate(-1);
          }}
          showBreadcrumb={isDesktop}
          showTitleInBreadcrump={false}
          width="50%"
          className="sidebar-style-2 idea-submission"
          backText={'general.back'}
          side="right"
          scrollShadow={!isDesktop}
        >
          <IdeaSubmisson />
        </SidebarDrawer>
      ) : null}

      {(openProjectView || openIdeaView || openVoteView) && searchParams.get('side') === 'right' && (
        <SidebarDrawer
          width="50%"
          className="sidebar-style-2"
          title={submittedProjectTitle}
          isOpen={openProjectView || openIdeaView || openVoteView}
          showBreadcrumb={isDesktop}
          backIcon={'right'}
          onClose={handleBackClick}
          breadcrumb={
            openIdeaView
              ? [
                  { name: 'Sākums' },
                  { name: 'Mana līdzdalība', goBack: true },
                  {
                    name: intl.formatMessage({ id: 'my_participation.tab_title.ideas' }),
                    path: '/main?my-participation=open&tab=ideas_tab',
                    withState: true,
                  },
                ]
              : [{ name: 'Sākums' }, { name: 'Mana līdzdalība', goBack: true }]
          }
          dividerVisible={false}
          backText={'general.back'}
          side={'right'}
          scrollShadow={!isDesktop}
        >
          {openIdeaView && <IdeaView setSubmittedIdeaTitle={setSubmittedProjectTitle} />}
          {openVoteView && <ProjectView setSubmittedProjectTitle={setSubmittedProjectTitle} />}
          {openProjectView && <ProjectView setSubmittedProjectTitle={setSubmittedProjectTitle} />}
        </SidebarDrawer>
      )}
      <RightSidebars
        setIsOpenedEmbedded={setIsOpenedEmbedded}
        isOpenEmbedded={isOpenEmbedded}
        setVisibleLayers={setVisibleLayers}
      />
    </>
  );

  const renderLayout = () => {
    const isSidebarOpen = Object.keys(states.leftSidebars).length || Object.keys(states.rightSidebars).length;
    const panels: SplitterProps['panels'] = [
      { children: renderMap(), min: 255 },
      { children: renderSideBars(), size: isSidebarOpen ? undefined : 0, min: isSidebarOpen ? 75 : undefined },
    ];

    if (isDesktop) {
      return [renderMap(), renderSideBars()];
    }

    return <Splitter panels={panels} layout="vertical" className="mobile-splitter" onResize={(e) => setHeight(e[0])} />;
  };

  return (
    <StyledPage
      notificationHeight={notificationHeight}
      className={ClassNameUtil.create([renderFooter && 'hide-footer'])
        .add({ isOpenMapClickResults, isOpenUserNotification })
        .getClassName()}
    >
      {renderLayout()}
      {!isDesktop && !isSidebarOpen && <PortalSectionButtons />}
      <UnauthenticatedModal setShowModal={setIsOpenUnauthenticated} showModal={isOpenUnauthenticated} />
    </StyledPage>
  );
};

export default LayoutPage;
