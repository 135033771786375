import { useIntl } from 'react-intl';
import { type BreadcrumbObject, Button, Divider, SidebarDrawer, Spinner, Tabs, TabsProps, Tooltip } from 'ui';
import { NotificationSide } from './components/NotificationSide';
import { StyledMunicipalProject } from './style';
import { ProjectSection } from './components/ProjectSection';
import { type EventInterface, EventSection } from './components/EventSection';
import { SpecialistInfo } from './components/SpecialistInfo';
import React, { type ReactNode, useEffect, useState } from 'react';
import useQueryApiClient from 'utils/useQueryApiClient';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { RenderTabBar } from 'rc-tabs/lib/interface';
import { Flex } from 'antd';
import FileDownload from 'js-file-download';
import toastMessage from 'utils/toastMessage';
import { EventView } from './components/EventSection/EventView';
import CloseButton from 'components/CloseButton';
import { useSystemSettingState } from 'contexts/SystemSettingContext';

type TabItem = Omit<Exclude<TabsProps['items'], undefined>[number], 'label' | 'key'> & {
  label: string;
  key: 'projects' | 'events';
};

export const MunicipalProject = ({ isOpenMunicipalProject, closeProject }: any) => {
  const [hasVotingPeriod, setHasVotingPeriod] = useState<boolean>(false);
  const [hasSubmissionPeriod, setHasSubmissionPeriod] = useState<boolean>(false);
  const [municipal, setMunicipal] = useState<any>();
  const [selectedYear, setSelectedYear] = useState(String(dayjs().year()));
  const [projects, setProjects] = useState<any[]>([]);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbObject[]>([]);
  const [drawerTitle, setDrawerTitle] = useState<string>('');
  const [selectedEvent, setSelectedEvent] = useState<EventInterface | undefined>();
  const [eventActivePage, setEventActivePage] = useState<number>(1);
  const [activeKey, setActiveKey] = useState<TabItem['key']>('projects');

  const intl = useIntl();
  let [searchParams] = useSearchParams();
  const atvkId = searchParams.get('atvk_id') || undefined;
  const isModalOpen = searchParams.get('municipal-modal');
  const {
    device: { isDesktop },
  } = useSystemSettingState();

  const { refetch, isLoading: isMunicipalLoading } = useQueryApiClient({
    request: {
      url: `/api/v1/tapis/participation_budgets/${atvkId}`,
      method: 'GET',
      disableOnMount: true,
    },
    onSuccess: (response) => {
      const afterSub = dayjs(response.submission_period_from).startOf('d').isBefore(dayjs().endOf('d'));
      const beforeSub = dayjs(response.submission_period_to).endOf('d').isAfter(dayjs().startOf('d'));

      setHasSubmissionPeriod(afterSub && beforeSub);

      const after = dayjs(response.voting_period_from).startOf('d').isBefore(dayjs().endOf('d'));
      const before = dayjs(response.voting_period_to).endOf('d').isAfter(dayjs().startOf('d'));

      setHasVotingPeriod(after && before);
      setMunicipal(response);
      setDrawerTitle(response?.name);
    },
  });

  const { appendData: exportProjects, isLoading: isExporting } = useQueryApiClient({
    request: {
      url: '/api/v1/tapis/projects/export',
      method: 'GET',
      multipart: true,
      disableOnMount: true,
    },
    onSuccess: (response) => {
      FileDownload(response, `project_list_${dayjs().format('DD_MM_YYYY')}.pdf`);
      toastMessage.success(intl.formatMessage({ id: 'general.download_success' }));
    },
  });

  useEffect(() => {
    if (atvkId && isModalOpen !== 'open') {
      refetch();
    }
  }, [searchParams]);

  const tabSelectorData: TabItem[] = [
    {
      key: 'projects',
      children: (
        <ProjectSection
          onTabChange={setSelectedYear}
          onProjectListChange={setProjects}
          onEmpty={() => setActiveKey('events')}
        />
      ),
      label: 'participation_budget.project',
    },
    {
      children: (
        <EventSection
          atvkId={atvkId}
          onEventClick={(event) => onEventClick(event)}
          activePage={eventActivePage}
          setActivePage={setEventActivePage}
        />
      ),
      label: 'participation_budget.events',
      key: 'events',
    },
  ];

  const onEventClick = (event: EventInterface) => {
    const eventBreadcrumbs: BreadcrumbObject[] = [
      { name: intl.formatMessage({ id: 'general.start' }), onClick: () => onClose('event') },
      { name: municipal?.name, onClick: () => onClose('event') },
    ];

    setSelectedEvent(event);
    setDrawerTitle(event.news_name);
    setBreadcrumbs(eventBreadcrumbs);
  };

  const onPrint = (projects: any[]) => {
    if (projects.length) {
      exportProjects({ projectIds: projects.map(({ id }) => id), search: { year: selectedYear } });
    }
  };

  const excludedStatuses = ['submitted', 'did_not_qualify', 'ready_to_vote'];

  const renderTabBar: RenderTabBar = (props, DefaultTabBar) => (
    <Flex justify="space-between" align="center" className="wrapper-tab-bar">
      <DefaultTabBar {...props} />
      {props.activeKey === 'projects' && (
        <Tooltip hack title={intl.formatMessage({ id: 'participation_budget.projects_download_tooltip' })}>
          <Button
            className="action-print"
            icon="file-pdf"
            faBase="fa-light"
            label={intl.formatMessage({ id: 'participation_budget.projects_download' })}
            border={false}
            disabled={
              isExporting || !projects?.filter((project: any) => !excludedStatuses.includes(project.state))?.length
            }
            onClick={() => onPrint(projects)}
          />
        </Tooltip>
      )}
    </Flex>
  );

  const onClose = (type?: 'event') => {
    switch (type) {
      case 'event':
        setSelectedEvent(undefined);
        setDrawerTitle(municipal?.name);
        setBreadcrumbs([]);

        return;

      default:
        closeProject();
    }
  };

  const renderNotification = (type: 'vote' | 'submission') => {
    const renderMap: { [key in typeof type]: ReactNode } = {
      vote: (
        <NotificationSide
          message={intl.formatMessage(
            { id: `municipiality_can_vote_for_projects_message` },
            { until: dayjs(municipal?.voting_period_to).format('DD.MM.YYYY.') }
          )}
        />
      ),
      submission: (
        <NotificationSide
          message={intl.formatMessage(
            { id: `municipiality_submission_period_message` },
            { until: dayjs(municipal?.submission_period_to).format('DD.MM.YYYY.') }
          )}
        />
      ),
    };

    return renderMap[type];
  };

  const renderDrawerContent = () => {
    const isBudgetHeaderEmpty = !hasVotingPeriod && !hasSubmissionPeriod;

    if (selectedEvent) {
      return <EventView event={selectedEvent} />;
    }

    return (
      <StyledMunicipalProject>
        <Spinner spinning={isMunicipalLoading || isExporting}>
          <Tabs<TabItem['key']>
            type="card"
            activeKey={activeKey}
            className="municipal_project_tab"
            onChange={setActiveKey}
            items={tabSelectorData.map(({ key, children, label: id }) => {
              return {
                label: intl.formatMessage({ id }),
                key,
                children: (
                  <Flex gap={20} vertical>
                    {!isBudgetHeaderEmpty && <Divider />}
                    {hasVotingPeriod && renderNotification('vote')}
                    {hasSubmissionPeriod && renderNotification('submission')}
                    {hasSubmissionPeriod && (
                      <SpecialistInfo
                        hasSubmissionPeriod={hasSubmissionPeriod}
                        isThereSubmissionPeriod={Boolean(municipal?.submission_period_from)}
                        isMunicipalLoading={isMunicipalLoading}
                        budget={municipal}
                      />
                    )}
                    {children}
                  </Flex>
                ),
              };
            })}
            renderTabBar={renderTabBar}
          />
        </Spinner>
      </StyledMunicipalProject>
    );
  };

  return (
    <SidebarDrawer
      width="50vw"
      className={`sidebar-style-2 ${selectedEvent ? 'event-view' : ''} municipal-project`}
      title={drawerTitle}
      isOpen={isOpenMunicipalProject}
      showBreadcrumb={isDesktop && !!breadcrumbs.length}
      backIcon={'left'}
      onClose={isDesktop ? () => (selectedEvent ? onClose('event') : onClose()) : undefined}
      breadcrumb={breadcrumbs}
      backText={selectedEvent && 'general.back'}
      dividerVisible={isDesktop && !selectedEvent}
      side="left"
      closeIcon={isDesktop ? undefined : <CloseButton onClick={() => (selectedEvent ? onClose('event') : onClose())} />}
      scrollShadow={!isDesktop}
    >
      {renderDrawerContent()}
    </SidebarDrawer>
  );
};
