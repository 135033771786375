import { Button, ButtonProps, Tooltip, TooltipProps } from 'ui';
import React, { useState } from 'react';
import UnauthenticatedModal from '../Modals/UnauthenticatedModal';
import useTooltip, { LOGIN_TOOLTIP, ProjectOption } from 'utils/useTooltip';
import { useUserState } from 'contexts/UserContext';
import { useIntl } from 'react-intl';
import { ProjectType } from 'pages/LayoutPage/Components/Sidebars/Project';
import { useProjectState } from 'contexts/ProjectContext';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { useParticipationBudgetState } from '../../contexts/ParticipationBudgetContext';
import InformationModal from '../Modals/InformationModal';

export interface VotingProject extends ProjectOption, Pick<ProjectType, 'id' | 'has_voted'> {}

export type VoteHandler = (project: VotingProject) => void;

interface VoteButtonProps {
  project: VotingProject;
  button: ButtonProps;
  tooltip?: Omit<TooltipProps, 'title' | 'children'>;
  handleVote: VoteHandler;
}

const VoteButton = ({ project, button, tooltip, handleVote }: VoteButtonProps) => {
  const [isOpenAuthModal, setIsOpenAuthModal] = useState<boolean>(false);
  const [showInformationModal, setShowInformationModal] = useState<boolean>(false);

  let [searchParams] = useSearchParams();
  const { budgets } = useParticipationBudgetState();

  const atvkId = searchParams.get('atvk_id');
  const municipality = budgets?.find((municipal: any) => municipal.atvk_id === atvkId);

  const { getRestrictionTooltip } = useTooltip('');
  const user = useUserState();
  const intl = useIntl();
  const { voteMap = {} } = useProjectState();

  const hasVoted = voteMap[project.id] === undefined ? project.has_voted : voteMap[project.id];
  const restrictionTooltip = getRestrictionTooltip('vote-project', { user, project, municipality, hasVoted });
  const isDisabled =
    restrictionTooltip === LOGIN_TOOLTIP ||
    restrictionTooltip === 'voting_available_untill_end' ||
    restrictionTooltip === 'voting_available_untill'
      ? false
      : !!restrictionTooltip.length;

  const label = hasVoted
    ? intl.formatMessage({ id: 'participation_budget.projects_voted_btn' })
    : intl.formatMessage({ id: 'participation_budget.projects_tovote_btn' });

  const handleChangeStatus = (restrictionTooltip: string) => {
    switch (true) {
      case restrictionTooltip === LOGIN_TOOLTIP:
        setIsOpenAuthModal(true);
        break;

      case restrictionTooltip.length > 0 &&
        restrictionTooltip !== 'voting_available_untill_end' &&
        restrictionTooltip !== 'voting_available_untill':
        break;

      default:
        if (!municipality?.withdrawable_votes && !hasVoted) {
          setShowInformationModal(true);
        }

        handleVote(project);
    }
  };

  return (
    <div className="vote-button">
      <Tooltip
        hack
        {...tooltip}
        title={
          restrictionTooltip &&
          intl.formatMessage(
            { id: restrictionTooltip },
            {
              date: municipality?.withdrawable_votes
                ? dayjs(municipality.voting_period_to).format('DD.MM.YYYY')
                : project.change_vote_to_datetime
                ? dayjs(project.change_vote_to_datetime).format('DD.MM.YYYY [plkst.] HH:mm')
                : dayjs().add(1, 'd').format('DD.MM.YYYY [plkst.] HH:mm'),
            }
          )
        }
      >
        <Button
          label={label}
          type={hasVoted ? undefined : 'primary'}
          {...button}
          onClick={() => handleChangeStatus(restrictionTooltip)}
          disabled={isDisabled}
          className={isDisabled ? 'gray-disabled' : ''}
        />
      </Tooltip>

      <UnauthenticatedModal setShowModal={setIsOpenAuthModal} showModal={isOpenAuthModal} />
      <InformationModal
        confirmText={intl.formatMessage({ id: 'if_changed_mine' })}
        setShowModal={setShowInformationModal}
        showModal={showInformationModal}
      />
    </div>
  );
};

export default VoteButton;
