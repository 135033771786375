import React, { Dispatch, SetStateAction } from 'react';
import { Icon, Modal, Button } from 'ui';
import { FormattedMessage, useIntl } from 'react-intl';

interface InformationModalProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  confirmText: string;
}

const InformationModal = ({ setShowModal, showModal, confirmText }: InformationModalProps) => {
  const intl = useIntl();

  const renderConfirmText = () => {
    return confirmText ? <FormattedMessage id={confirmText} /> : <FormattedMessage id="general.confirm_text" />;
  };

  return (
    <Modal
      onCancel={() => setShowModal(false)}
      open={showModal}
      closable={false}
      centered
      disableHeader
      getContainer={document.body}
      footer={
        <>
          <Button label={intl.formatMessage({ id: 'general.close' })} onClick={() => setShowModal(false)} />
        </>
      }
    >
      <h3 className="confirm-title">
        <Icon faBase="fal" icon="exclamation-circle" />
        {renderConfirmText()}
      </h3>
    </Modal>
  );
};

export default InformationModal;
