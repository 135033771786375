import { useTooltipState } from '../contexts/TooltipContext';
import { useLocation } from 'react-router-dom';
import useJwt from './useJwt';
import { UserGroups } from '../constants/userGroups';
import { State as UserInterface } from '../contexts/UserContext';
import { ProjectInterface } from '../pages/LayoutPage/Components/Sidebars/MunicipalProject/components/temporary-data';
import dayjs from 'dayjs';

export type ProjectOption = Pick<ProjectInterface, 'change_vote_to_datetime' | 'atvk_id'>;

interface TooltipOptions {
  user: UserInterface;
  project?: ProjectOption;
  section?: sectionType;
  municipality?: any; //todo
  hasVoted?: boolean;
}

interface RestrictionConfig {
  isTheRightRole: boolean;
  canUnvote: boolean;
  withdrawableVotes?: boolean;
  hasUnvoted: boolean;
  isOldEnough: boolean;
  isLoggedIn: boolean;
  isCitizen?: boolean;
  activeRole?: {
    emailVerified: boolean;
  };
  section?: TooltipOptions['section'];
}

interface TooltipResult {
  tooltip: string;
  getRestrictionTooltip: (type: TooltipHandlerType, options: TooltipOptions) => string;
}

type sectionType =
  | 'project-view'
  | 'map-region'
  | 'project-submission'
  | 'project-list'
  | 'notification-configuration'
  | 'idea-creation';

type TooltipHandlerType = 'vote-project' | 'submit-project' | 'email-verification';

type TooltipHandlerMapInterface = {
  [key in TooltipHandlerType]: (config: RestrictionConfig) => string;
};

export const LOGIN_TOOLTIP = 'general.need_to_login';

function useTooltip(name: any): TooltipResult {
  const TYPE_HANDLER_MAP: TooltipHandlerMapInterface = {
    'vote-project': getVoteRestrictionTooltip,
    'submit-project': getSubmitRestrictionTooltip,
    'email-verification': getEmailVerificationRestriction,
  };

  const { tooltips } = useTooltipState();
  const location = useLocation();
  const { isTokenActive } = useJwt();

  let convertedName = name;

  if (Array.isArray(name)) {
    convertedName = name[name.length - 1];
  }

  return {
    tooltip: tooltips.find((e: any) => location.pathname.includes(e.url) && e.code === convertedName)?.translation,
    getRestrictionTooltip: (type: TooltipHandlerType, options: TooltipOptions) =>
      TYPE_HANDLER_MAP[type](getRestrictionConfig(options, isTokenActive())),
  };
}

const getRestrictionConfig = (options: TooltipOptions, isLoggedIn: boolean): RestrictionConfig => {
  const {
    user,
    municipality,
    hasVoted,
    project: { atvk_id: projectAtvkId, change_vote_to_datetime } = {},
    section,
  } = options;
  const activeRole = user.roles.find((e) => e.id === user.selectedRole);
  const isCitizen = projectAtvkId === undefined ? undefined : user.atvkCode !== projectAtvkId;

  return {
    isTheRightRole: [UserGroups.authenticated, UserGroups.proxy].includes(activeRole?.code as any),
    isOldEnough: user.isOverSixteen,
    isCitizen,
    isLoggedIn,
    activeRole,
    section,
    canUnvote: change_vote_to_datetime ? dayjs().isBefore(dayjs(change_vote_to_datetime)) : true,
    hasUnvoted: !!hasVoted,
    withdrawableVotes: municipality?.withdrawable_votes,
  };
};

const getVoteRestrictionTooltip = ({
  isTheRightRole,
  isOldEnough,
  isCitizen,
  isLoggedIn,
  canUnvote,
  hasUnvoted,
  withdrawableVotes,
}: RestrictionConfig): string => {
  switch (true) {
    case !isLoggedIn:
      return LOGIN_TOOLTIP;

    case !isTheRightRole:
      return 'user_with_individual_role_can_vote';

    case !isOldEnough:
      return 'old_enough_user_can_vote';

    case !(isCitizen === false):
      return 'own_municipality_user_can_vote';

    case !canUnvote && !withdrawableVotes:
      return 'voting_not_available';
    case hasUnvoted && withdrawableVotes:
      return 'voting_available_untill_end';
    case hasUnvoted && !withdrawableVotes:
      return 'voting_available_untill';

    default:
      return '';
  }
};

const getSubmitRestrictionTooltip = ({
  isTheRightRole,
  isOldEnough,
  isLoggedIn,
  activeRole,
  section,
}: RestrictionConfig): string => {
  switch (true) {
    case !isLoggedIn:
      return 'general.need_to_login';

    case !isTheRightRole:
      switch (section) {
        case 'project-submission':
        case 'project-list':
          return 'participation_budget.only_physic_user_can_vote';

        default:
          return 'only_physical_person_can_view_section';
      }
    case !isOldEnough:
      return 'old_enough_user_can_submit_project';

    case !activeRole?.emailVerified:
      return 'notification.to_submit_verify_email';

    default:
      return '';
  }
};

const getEmailVerificationRestriction = ({ activeRole, section }: RestrictionConfig): string => {
  const defaultResult = '';

  if (activeRole?.emailVerified) {
    return defaultResult;
  }

  switch (section) {
    case 'project-view':
    case 'map-region':
      return 'notification.email_not_verified';

    case 'notification-configuration':
      return 'notification.provide_verify_email';

    case 'idea-creation':
      return 'notification.to_create_idea_verify_email';

    default:
      return defaultResult;
  }
};

export default useTooltip;
