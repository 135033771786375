import { StyledCheckboxDirectoryTreeComponent } from './styles';
import { Tree } from 'antd';
import type { DataNode, DirectoryTreeProps, TreeProps } from 'antd/es/tree';

interface CheckboxDirectoryTreeProps extends Pick<TreeProps, 'onClick'> {
  items: DataNode[];
  showIcon?: boolean;
  onCheck?: DirectoryTreeProps['onCheck'];
  defaultExpandedKeys: string[];
  checkedKeys: string[];
  onExpand?: DirectoryTreeProps['onExpand'];
  onSelect?: DirectoryTreeProps['onSelect'];
  expandedKeys?: string[];
}

const onCheckDefault: DirectoryTreeProps['onCheck'] = (checkedKeys, info) => {
  console.log('onCheck', checkedKeys, info);
};

const CheckboxDirectoryTree = ({
  items,
  showIcon = false,
  onCheck = onCheckDefault,
  defaultExpandedKeys,
  checkedKeys = [],
  onExpand,
  onSelect,
  expandedKeys,
  onClick,
}: CheckboxDirectoryTreeProps) => {
  return (
    <StyledCheckboxDirectoryTreeComponent>
      <Tree
        checkable
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        onSelect={onSelect}
        onCheck={onCheck}
        treeData={items}
        showIcon={showIcon}
        defaultExpandedKeys={defaultExpandedKeys}
        checkedKeys={checkedKeys}
        selectable={false}
        onClick={onClick}
      />
    </StyledCheckboxDirectoryTreeComponent>
  );
};

export default CheckboxDirectoryTree;
