import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { StyledProjects } from './style';
import {
  Button,
  Input,
  Pagination,
  ProjectCard,
  Select,
  SelectOption,
  Spinner,
  ProjectItemProps,
  scrollToTop,
} from 'ui';
import { Col, Flex, Form, Row, Tag } from 'antd';
import { useIntl } from 'react-intl';
import usePagination from 'utils/usePagination';
import { useProjectState, useProjectDispatch, SearchInterface } from 'contexts/ProjectContext';
import { CoordinatesWindowPosition } from 'pages/LayoutPage';
import { useParticipationBudgetState } from 'contexts/ParticipationBudgetContext';
import useTooltip from 'utils/useTooltip';
import { useUserState } from 'contexts/UserContext';
import ApiSelect from 'components/Selects/ApiSelect';
import { useSystemSettingState } from 'contexts/SystemSettingContext';
import { ClassNameUtil } from 'utils/className';

interface ProjectVersion extends Omit<ProjectType, 'versions' | 'pictures'> {
  pictures: Array<{
    blob: any;
    custom_name: string;
    url: string;
  }>;
}

export interface ProjectType {
  id: number;
  name: string;
  title: string;
  voted: boolean;
  pictures: string[];
  the_geom: string;
  state: string;
  isTheUserCreator: boolean;
  versions: ProjectVersion[];
  modalCoord?: any;
  has_voted?: boolean;
  coordinatesForModal?: CoordinatesWindowPosition;
  atvk_id: string;
  can_vote: boolean;
  project_id?: number;
  year: number;
  submission_period_to?: string;
}

interface ProjectPropType {
  isOpenProjectSearch: boolean;
  setIsOpenProjectSearch: Dispatch<SetStateAction<boolean>>;
}

interface ProjectSearchInterface
  extends Pick<SearchInterface, 'state' | 'territorial_units' | 'atvk_id' | 'name' | 'category'> {}

const Project = ({ isOpenProjectSearch, setIsOpenProjectSearch }: ProjectPropType) => {
  const [projectsListed, setProjectsListed] = useState<boolean>(false);
  const [projects, setProjects] = useState<ProjectType[] | []>([]);
  const [activeProject, setActiveProject] = useState<number | null>(null);

  const intl = useIntl();
  const [form] = Form.useForm<ProjectSearchInterface>();
  const theme = localStorage.getItem('selected-theme');
  const { projects: projectsDataApi, isLoading, search } = useProjectState();
  const dispatchProjects = useProjectDispatch();
  const { budgets } = useParticipationBudgetState();
  const { getRestrictionTooltip } = useTooltip('');
  const user = useUserState();
  const {
    device: { isDesktop },
  } = useSystemSettingState();

  const publicStatus = [
    { id: '1', value: 'in_voting', name: 'Balsošanā' },
    { id: '2', value: 'voting_is_closed', name: 'Balsošana noslēgusies' },
    { id: '3', value: 'supported', name: 'Atbalstīts' },
    { id: '4', value: 'being_implemented', name: 'Tiek īstenots' },
    { id: '5', value: 'realized', name: 'Realizēts' },
    { id: '6', value: 'not_supported', name: 'Neatbalstīts' },
  ];

  useEffect(() => {
    setProjects(getSorted(projectsDataApi));
  }, [projectsListed, projectsDataApi]);

  useEffect(() => {
    if (activeProject) {
      dispatchProjects({
        type: 'HIGHLIGHT_PROJECT',
        payload: activeProject,
      });
    }
  }, [activeProject]);

  const extractTerritories = (
    territories: ProjectSearchInterface['territorial_units'] = [],
    targetType: 'territorial-units' | 'neighbourhoods'
  ): string[] => {
    return territories
      .map((item) => {
        const parts = item.split('_');
        const territory = parts.shift();
        const type = parts.pop();

        return type === targetType ? territory : undefined;
      })
      .filter(Boolean) as string[];
  };

  const getTerritorialSearch = (
    territories: ProjectSearchInterface['territorial_units'] = []
  ): { territorial_units?: string[]; neighbourhoods?: string[] } => {
    return territories.length
      ? {
          territorial_units: extractTerritories(territories, 'territorial-units'),
          neighbourhoods: extractTerritories(territories, 'neighbourhoods'),
        }
      : {};
  };

  const onFinish = (filters: ProjectSearchInterface) => {
    const territorialSearch = getTerritorialSearch(filters.territorial_units);

    if (filters?.state?.length) {
      dispatchProjects({
        type: 'REFETCH',
        payload: {
          search: {
            ...filters,
            ...territorialSearch,
          },
        },
      });
      return;
    }
    const status = publicStatus.map((el) => el.value);
    dispatchProjects({
      type: 'REFETCH',
      payload: {
        search: {
          ...filters,
          ...territorialSearch,
          state: status,
        },
      },
    });
  };

  const getSorted = (projects: ProjectType[]) => {
    let processedIds: number[] = [];

    const hasVoted = projects
      .filter(
        ({ has_voted, state, id }: ProjectType) => has_voted && state === 'in_voting' && !processedIds.includes(id)
      )
      .sort(sortByVotingRestrictions);

    processedIds.push(...hasVoted.map(({ id }) => id));

    const hasNotVoted = projects
      .filter(
        ({ has_voted, state, id }: ProjectType) => !has_voted && state === 'in_voting' && !processedIds.includes(id)
      )
      .sort(sortByVotingRestrictions);

    processedIds.push(...hasNotVoted.map(({ id }) => id));

    const notInVoting = projects.filter(({ can_vote, id }: ProjectType) => !can_vote && !processedIds.includes(id));

    return hasVoted.concat(hasNotVoted).concat(notInVoting);
  };

  const sortByVotingRestrictions = (a: ProjectType, b: ProjectType) => {
    switch (true) {
      case a.can_vote && b.can_vote:
        const stringA = getRestrictionTooltip('vote-project', { user, project: a });
        const stringB = getRestrictionTooltip('vote-project', { user, project: b });

        return stringA.length - stringB.length;

      case a.can_vote:
        return -1;

      case b.can_vote:
        return 1;

      default:
        return 0;
    }
  };

  const renderTag = (props: any) => {
    const { value, label, closable, onClose } = props;
    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag className={'status status-' + value} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}>
        {label}
      </Tag>
    );
  };

  const storedPage = sessionStorage.getItem('ProjectCurrentPage');

  const { currentPage, paginatedData, handlePageChange } = usePagination(
    projects,
    storedPage && parseInt(storedPage),
    projectsListed ? 9 : undefined
  );

  const handleChangeProjectList = (value: boolean) => {
    const newPageSize = value ? 9 : 6;
    const newTotalPages = Math.ceil(projects.length / (newPageSize || projects.length));
    const newPage = Math.min(currentPage, newTotalPages);

    setProjectsListed(value);
    handleProjectPageChange(newPage);
  };

  const handleProjectPageChange = (page: number) => {
    sessionStorage.setItem('ProjectCurrentPage', page.toString());
    handlePageChange(page);
    scrollToTop(isDesktop);
  };

  const getSearchInitialValues = (): ProjectSearchInterface => {
    const { state, atvk_id, category, name, territorial_units } = search;

    return { state: state || ['in_voting'], name, atvk_id, territorial_units, category };
  };

  const resetSearchFields = () => {
    const fields: ProjectSearchInterface = {
      name: undefined,
      state: ['in_voting'],
      atvk_id: undefined,
      category: undefined,
      territorial_units: [],
    };

    form.setFieldsValue(fields);
    form.submit();
  };

  const renderFilterFields = () => {
    const formFields = [
      <Select
        name="atvk_id"
        placeholder={intl.formatMessage({ id: 'projects.for_example_riga' })}
        label={intl.formatMessage({ id: 'participation_budget.projects_select_municipality' })}
        optionFilterProp="children"
        allowClear
        className="municipality-selector"
      >
        {budgets.map((entry: any) => (
          <SelectOption key={entry.id} value={entry.atvk_id}>
            {entry.name}
          </SelectOption>
        ))}
      </Select>,
      <ApiSelect
        request={{ url: 'api/v1/tapis/territorial_units' }}
        name="territorial_units"
        placeholder={intl.formatMessage({ id: 'participation_budget.territory_selector_placeholder' })}
        label={intl.formatMessage({ id: 'participation_budget.projects_select_territorialunit' })}
        formatOptions={(items, searchString) => (searchString.length ? items : items.slice(0, 6))}
      />,
      <Select
        name="state"
        className="dimmed"
        mode="multiple"
        label={intl.formatMessage({ id: 'participation_budget.projects_select_statusofprojects' })}
        tagRender={renderTag}
      >
        {publicStatus.map((status) => (
          <SelectOption
            key={status.id}
            value={status.value}
            className={` project-status-${(theme === 'default' || !theme) && status.value}`}
          >
            {intl.formatMessage({ id: `participation_budget.${status.value}` })}
          </SelectOption>
        ))}
      </Select>,
      <ApiSelect
        name="category"
        placeholder={intl.formatMessage({ id: 'participation_budget.category_selector_placeholder' })}
        label={intl.formatMessage({ id: 'participation_budget.projects_select_category' })}
        size="large"
        request={{ url: 'api/v1/tapis/categories' }}
        formatOptions={(items, searchString) => (searchString.length ? items : items.slice(0, 6))}
      />,
    ];

    return (
      <>
        <div className="projects__selects-wrapper">
          {isDesktop ? (
            formFields
          ) : (
            <Row gutter={[0, 4]}>
              {formFields.map((reactNode) => (
                <Col span={24}>{reactNode}</Col>
              ))}
            </Row>
          )}
        </div>
        <div className="projects__clear-selects">
          <Button
            icon="trash-can"
            faBase="fa"
            label={intl.formatMessage({ id: 'participation_budget.projects_selects_clear' })}
            border={false}
            onClick={() => resetSearchFields()}
          />
        </div>
      </>
    );
  };

  const renderSearch = () => {
    const formFields = [
      <Input
        name="name"
        label={intl.formatMessage({ id: 'participation_budget.projects_search_input' })}
        className="projects__input"
        placeholder={intl.formatMessage({ id: 'participation_budget.projects_search_input_placeholder' })}
        rules={[{ min: 3, message: intl.formatMessage({ id: 'validation.search_min_len' }, { min: 3 }) }]}
      />,
      <Button
        loading={isLoading}
        htmlType="submit"
        type="primary"
        label={intl.formatMessage({ id: 'participation_budget.projects_search_input' })}
        block={!isDesktop}
        className="submit-button"
      />,
      isOpenProjectSearch ? (
        <Button
          className="close-filter-button"
          icon="xmark"
          faBase="fa"
          onClick={() => setIsOpenProjectSearch(false)}
        />
      ) : (
        <Button
          className="open-filter-button"
          label={intl.formatMessage({ id: 'participation_budget.projects_filter_button' })}
          onClick={() => setIsOpenProjectSearch(true)}
          block={!isDesktop}
        />
      ),
    ];

    return (
      <>
        <div className="projects__input-button-wrapper">
          {isDesktop ? (
            formFields
          ) : (
            <Row gutter={[20, 15]}>
              {formFields.map((reactNode, i) => (
                <Col span={i > 0 ? 12 : 24} flex={i > 0 ? '1' : ''}>
                  {reactNode}
                </Col>
              ))}
            </Row>
          )}
        </div>
        {isOpenProjectSearch && renderFilterFields()}
      </>
    );
  };

  const renderListHeader = () => {
    const children = [
      <span>
        {intl.formatMessage({ id: 'participation_budget.projects_together' }, { projects: projects.length })}
      </span>,
      <div className="projects__content-header-btns">
        <Button
          icon="grid-2"
          faBase="fa-regular"
          label={intl.formatMessage({ id: 'participation_budget.projects_behaviour_column' })}
          className={projectsListed ? '' : 'selected-project-format'}
          border={false}
          onClick={() => handleChangeProjectList(false)}
        />
        <Button
          icon="list"
          faBase="fa-regular"
          label={intl.formatMessage({ id: 'participation_budget.projects_behaviour_list' })}
          className={!projectsListed ? '' : 'selected-project-format'}
          border={false}
          onClick={() => handleChangeProjectList(true)}
        />
      </div>,
    ];
    return (
      <div className="projects__content-header">
        {isDesktop ? (
          children
        ) : (
          <Flex vertical gap={16}>
            {children}
          </Flex>
        )}
      </div>
    );
  };

  const getMobileLayoutHandlers = (): ProjectItemProps['renderLayout'] => {
    return {
      list: ({ image, title, voteBtn, seeMore }) => (
        <Flex vertical className="card-content-layout w-full" gap={20}>
          {image()}
          {title()}
          <Flex justify="space-between" className="card-actions">
            {voteBtn()}
            {seeMore()}
          </Flex>
        </Flex>
      ),
      compact: ({ image, title, voteBtn, seeMore }) => (
        <Flex vertical className="card-content-layout w-full" gap={20}>
          <Flex gap={20} className="card-header">
            {image()}
            {title()}
          </Flex>
          <Flex justify="space-between" className="card-actions">
            {voteBtn()}
            {seeMore()}
          </Flex>
        </Flex>
      ),
    };
  };

  const renderListItem = (project: ProjectType) => (
    <ProjectCard
      key={project.id}
      listView={projectsListed}
      project={project}
      isVisibleSeDescBtn
      className={ClassNameUtil.create([
        'ellipse-title project-card',
        activeProject === project.id && 'bordered',
        !isDesktop && 'mobile',
      ]).getClassName()}
      setActiveProject={setActiveProject}
      projectViewSide="left"
      id={project.id}
      imageSize={projectsListed ? 'small' : 'medium'}
      renderLayout={isDesktop ? undefined : getMobileLayoutHandlers()}
    />
  );

  const renderList = () => {
    const className = projectsListed ? 'list-view' : 'compact-view';

    if (isDesktop) {
      return <div className={className.concat(' projects__content-body')}>{paginatedData.map(renderListItem)}</div>;
    }

    return (
      <Flex className={className} vertical gap={20}>
        {paginatedData.map(renderListItem)}
      </Flex>
    );
  };

  return (
    <StyledProjects className="projects">
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={getSearchInitialValues()}>
        {renderSearch()}
      </Form>
      <div className="projects__content">
        {renderListHeader()}
        <Spinner spinning={isLoading}>{renderList()}</Spinner>
        {projects.length > 6 && (
          <Pagination
            onChange={handleProjectPageChange}
            defaultCurrent={1}
            current={currentPage}
            total={projects.length}
            pageSize={projectsListed ? 9 : undefined}
            showSizeChanger={false}
            className="default project-list-pagination"
            compact
          />
        )}
      </div>
    </StyledProjects>
  );
};

export default Project;
